<template>
    <div>
        <div class="header" id="header">
            <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/组763_1686894590711.png" class="hwlogo" @click="toindex">
            <div class="headericon" id="headericon" @click="headericon"></div>
        </div>
        <div class="headerhidden" id="headerhidden">
            <div class="headeritem" @click="toindex"> <span> 首页 </span> </div>
            <div class="headeritem" v-for="(item) in columnData" :key="item.id" @click="toLink(item.url)">
                <span>{{item.columnName}}</span>
            </div>
        </div>
        <div style="height: 1.08rem;"></div>


        <div class="topBox">
            <div class="companyNameBox">
                <img :src="companyDetail.logo">
                <p>{{companyDetail.companyName}}</p>
            </div>
            <div class="companyInfoBox">
                <!-- <p class="companyInfo1">2023年电影作品（1部）</p> -->
                <!-- <div class="companyInfoLine"></div> -->
                <div class="companyInfo2Box">
                    <div class="companyInfo2Item">
                        <p>电影作品</p>
                        <p>{{companyDetail.representativeWorksNum}}部</p>
                    </div>
                    <div class="companyInfo2Line"></div>

                    <div class="companyInfo2Item">
                        <p>出品总票房</p>
                        <p>{{this.bigNumberTransform(companyDetail.boxOffice * 10000).value + this.bigNumberTransform(companyDetail.boxOffice * 1000).unit}}</p>
                    </div>

                    <div v-if="companyDetail.boxOfficeRanking > 0" class="companyInfo2Line"></div>
                    <div class="companyInfo2Item" v-if="companyDetail.boxOfficeRanking > 0">
                        <p>出品票房排名</p>
                        <p>{{companyDetail.boxOfficeRanking}}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="companyProfileBox">
            <p class="companyProfileTitle">公司简介</p>
            <p class="companyProfile companyProfileS" v-html="companyDetail.cpmpanyIntro"></p>
            <img class="companyProfileJT" @click="companyProfileComplete()" src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/路径414_1684735499306.png">
        </div>


        <div class="toGrzx" @click="toGrzx">
            <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/个人中心(1)(1)_1686566476728.png" class="toGrzxImg">
        </div>





    <div class="searchbox close-searchbox">


        <div class="modal-dialog close-modal-dialog animated bounceIn" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" @click="closeSearch">
                        <span>×</span></button>
                    <h4 class="modal-title" id="myModalLabel">
                        登录</h4>
                </div>
                <div class="modal-body">
                    <div class="input-group">
                        <input id="k" placeholder="请填入您的手机号码" v-model="phone">
                    </div>
                    <div class="input-group">
                        <input id="p" placeholder="请输入验证码" v-model="code">
                        <div class="sendcode" @click="sendcode">发送验证码</div>
                    </div>
                    <span class="btn" @click="doSubmit()">登录</span>
                </div>
            </div>
        </div>


    </div>



    
    </div>




    
</template>


<script>
import Cookies from 'js-cookie'
import {getListColumn,getMovie,listQa,listArticle,movieCompanyQueryById} from "@/api/index"
import {Loading} from 'element-ui';
import store from '@/store'
export default {
    data(){
        return {
            topnavaindex : 0,
            navtop : null,
            editContent1 : null,
            editContent2 : null,
            movieDetail : {
                "projectImagePath":"https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/矩形734_1684741356685.png",
                "movieName":"《误杀：瞒天过海》电影筹拍预展《误杀：瞒天过海》电影筹拍预展",
                "director":"路阳",
                "protagonist":"雷佳音、董子健、杨幂、于和伟、郭京飞、佟丽娅、董子健、杨幂、于和伟、郭京飞、佟丽娅",
                "themeGenre":"动作",
            },
            moviefrom : null,
            qalist : null,
            articlelist : null,
            headerflag : false,
            hiddenflag : false,
            headheight : null,
            topheight : null,
            xsjjboxtop : null,
            yyzrboxtop : null,
            cglcboxtop : null,
            xgyxboxtop : null,
            rmxmboxtop : null,
            cgdyboxtop : null,
            load : null,
            ywltoken:null,
            flag:false,
            phone:"",
            code:"",
            yzmt:null,
            yzms:60,
            yzmflag:true,
            columnData:null,
            companyDetail:{
                "boxOffice": 0,
                "boxOfficeRanking": null,
                "companyName": "",
                "cpmpanyIntro": "",
                "createBy": "",
                "createTime": "",
                "delFlag": 0,
                "id": "",
                "legalPerson": "",
                "logo": "",
                "mainProductionNum": 0,
                "representativeWorksNum": 0,
                "status": 0,
                "sysOrgCode": "",
                "uniformCreditCode": "",
                "updateBy": "",
                "updateTime": ""
            }
        }
    },
    methods:{
        toGrzx(){
            if(!Cookies.get('wjsToken')){
                this.$router.push({name:'dl'});
            }else{
                this.$router.push({name:'grzx'});
            }
        },
        toBack(){
            this.reset()
            // if(this.$route.params.linkType && this.$route.params.linkType == 1){
            //     this.$router.push({name:'index'});
            // }else if(this.$route.params.linkType && this.$route.params.linkType == 2){
            //     this.$router.push({name:'movielist'});
            // }else{
            //     this.$router.push({name:'index'});
            // }
            this.$router.back(-1)
        },
        toindex(){
            this.reset()
            this.$router.push({name:'index'});
        },

        getElementTop(el) {
            var actualTop = el.offsetTop
            var current = el.offsetParent
            while (current !== null) {
                actualTop += current.offsetTop
                current = current.offsetParent
            }
            return actualTop
        },
        windowscroll(){
            var t = document.documentElement.scrollTop || document.body.scrollTop;
            //var nav = document.getElementsByClassName("nav")[0];
            var vw = document.documentElement.clientWidth / 7.5;
            this.headheight = document.documentElement.clientWidth / 7.5 * 1.08;
            this.topheight = document.documentElement.clientWidth / 7.5 * 1.68;




            // if( t >= this.navtop){
            //     nav.classList.add("navfixed")
            // }else{
            //     nav.classList.remove("navfixed")
            // }



        },
        getElementTop(el) {
            var actualTop = el.offsetTop
            var current = el.offsetParent
            while (current !== null) {
                actualTop += current.offsetTop
                current = current.offsetParent
            }
            return actualTop
        },
        navactive(Index) {
            var navitem = document.getElementsByClassName("navitem");
            var navxian = document.getElementsByClassName("navxian")[0];
            for (let i = 0; i < navitem.length; i++) {
                navitem[i].classList.remove('navactive')
            }
            navitem[Index].classList.add('navactive')
            navxian.style.left = `calc(0.22rem * ${Index * 2 + 1} + 1.04rem * ${Index} + 0.52rem - 0.23rem)`
        },
        headericon(){
            var headericon = document.getElementById("headericon")
            var headerhidden = document.getElementById("headerhidden")
            if(this.headerflag){
                headerhidden.style.top = "-100%"
                headericon.classList.remove("headericon2")
                headericon.classList.add("headericon")
                this.headerflag = false
            }else{
                headerhidden.style.top = "1.06rem"
                headericon.classList.remove("headericon")
                headericon.classList.add("headericon2")
                this.headerflag = true
            }
        },
        headjt(){
            var headjt = document.getElementById("headjt")
            var hiddenbox = document.getElementById("hiddenbox")
            if(this.hiddenflag){
                hiddenbox.style.height = "0"
                headjt.style.transform = "rotateZ(0deg)"
                this.hiddenflag = false
            }else{
                hiddenbox.style.height = "4rem"
                headjt.style.transform = "rotateZ(-180deg)"
                this.hiddenflag = true
            }
        },
        reset(){
        this.headerflag = false
        this.hiddenflag = false
        var headericon = document.getElementById("headericon")
        var headerhidden = document.getElementById("headerhidden")
        //var headjt = document.getElementById("headjt")
        //var hiddenbox = document.getElementById("hiddenbox")
        headerhidden.style.top = "-100%"
        headericon.classList.remove("headericon2")
        headericon.classList.add("headericon")
        //hiddenbox.style.height = "0"
        //headjt.style.transform = "rotateZ(0deg)"
    },
        

        showLoading(){
            this.load && this.load.close(); 
            this.load = Loading.service({
                lock: true,
                text: '加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
        },
        showlogin(){
            document.getElementsByClassName("modal-dialog")[0].classList.remove("close-modal-dialog")
            document.getElementsByClassName("searchbox")[0].classList.remove("close-searchbox")
            document.body.style.overflow='hidden'
        },

        closeSearch(){
            document.getElementsByClassName("modal-dialog")[0].classList.add("close-modal-dialog")
            document.getElementsByClassName("searchbox")[0].classList.add("close-searchbox")
            document.body.style.overflow='visible'
            // if(!this.flag){
            //     setTimeout(()=>{
            //         document.getElementsByClassName("modal-dialog")[0].classList.remove("close-modal-dialog")
            //         document.getElementsByClassName("searchbox")[0].classList.remove("close-searchbox")
            //         document.body.style.overflow='hidden'
            //     },10000)
            // }
        },
        doSubmit(){
            if (this.phone == "" || this.phone.replace(" ", "") == "") {
                alert("请填写您的手机号");
                return;
            }
            if (!(/^1[23456789]\d{9}$/.test(this.phone))) {
                alert("请输入有效的手机号码");
                return;
            }
            if (this.code == "" || this.code.replace(" ", "") == "") {
                alert("请填写验证码");
                return;
            }

            this.showLoading()

            loginBySms({
                "phoneNum": this.phone,
                "smsCode": this.code,
                "source": "引未来官网"
            }).then(res => {
                console.log(res.token)
                this.load && this.load.close();
                Message.success({
                    message: "登录成功",
                    center: true,
                    duration:1200,
                    showClose: true,
                })
                Cookies.set('ywltoken', res.token, { expires: 7 })
                this.ywltoken = res.token;

            }).catch(res => {
                console.log(res.message)
            })


        },
        showLoading(){
            this.load && this.load.close(); 
            this.load = Loading.service({
                lock: true,
                text: '登录中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
        },
        sendcode(){
            var yzmbtn = document.getElementsByClassName("sendcode")[0];
            if (!this.phone) {
                alert("请填写您的手机号");
                return;
            }
            if (!(/^1[23456789]\d{9}$/.test(this.phone))) {
                alert("输入的手机号码不正确，请重新输入");
                return;
            }


            if(this.yzmflag){
                this.yzms = 60;
                this.yzmflag = false;

                sendCode({
                    "phoneNum": this.phone
                }).then(res => {
                    console.log(res)
                })


                this.yzmt = setInterval(() => {
                    //console.log(this.yzms)
                    
                    this.yzms--;
                    yzmbtn.innerHTML = this.yzms + "s";

                    if(this.yzms < 0){
                        clearInterval(this.yzmt)
                        this.yzmt = null;
                        yzmbtn.innerHTML = "获取验证码";
                        this.yzmflag = true;
                    }
                    
                    
                }, 1000);
            }else{

            }
        },
        closeyyDetailModal(){
            document.getElementsByClassName("yyDetailBox")[0].classList.add("close-yyDetailBox")
            //document.getElementsByClassName("searchbox")[0].classList.add("close-searchbox")
            document.body.style.overflow='visible'
        },
        showCloseyyDetailModal(index){
            document.getElementsByClassName("yyDetailBox")[0].classList.remove("close-yyDetailBox")
            document.body.style.overflow='hidden'
        },
        companyProfileComplete(){
            if(document.getElementsByClassName("companyProfile")[0].classList.contains("companyProfileS")){
                document.getElementsByClassName("companyProfile")[0].classList = "companyProfile";
                document.getElementsByClassName("companyProfileJT")[0].classList = "companyProfileJT companyProfileJTHide";

            }else{
                document.getElementsByClassName("companyProfile")[0].classList = "companyProfile companyProfileS";
                document.getElementsByClassName("companyProfileJT")[0].classList = "companyProfileJT";
            }
        },

        bigNumberTransform (value) {
            let param = {};
            let k = 10000,
            sizes = ['', '万', '亿', '万亿'],
            i;
            if(value < k){
                param.value =value.toFixed(2)
                param.unit=''
            }else{
                i = Math.floor(Math.log(value) / Math.log(k)); 
                param.value = ((value / Math.pow(k, i))).toFixed(2);
                param.unit = sizes[i];
            }
            return param;
        }

    },
    mounted(){
            console.log(this.$route.params.companyId)


            this.ywltoken =  Cookies.get('ywltoken') || null;
            console.log(this.ywltoken)

            
            this.showLoading()



            if(this.columnList){
                console.log("store" + this.columnList)
                this.columnData = this.columnList;
                this.load && this.load.close();
            }else{
                getListColumn().then(res => {
                    //console.log(res)
                    this.columnData = res.result;

                    store.dispatch('SetColumnList', res.result)


                })
            }


            if(this.$route.params.companyId){
                movieCompanyQueryById({
                    "id":this.$route.params.companyId
                }).then(res => {
                    this.companyDetail = res.result;
                })
            }else{
                this.toindex()
            }



                                // if(this.$route.params.movieId){
                                //     getMovieDetail({
                                //         "data": {
                                //             "movieId": this.$route.params.movieId
                                //         },
                                //         "mapParam": {}
                                //     }).then(res => {
                                //         this.movieDetail = res
                                //         this.editContent1 = res.movieContent
                                //         this.editContent2 = res.personContent
                                        //console.log(this.movieDetail)




                                        this.$nextTick(() => {





                                                var t = document.documentElement.scrollTop || document.body.scrollTop;
                                                //var nav = document.getElementsByClassName("nav")[0];
                                                var vw = document.documentElement.clientWidth / 7.5;
                                                this.headheight = document.documentElement.clientWidth / 7.5 * 1.08;
                                                this.topheight = document.documentElement.clientWidth / 7.5 * 1.68;
                                                //this.navtop = getElementTop(nav) - this.headheight;




                                                window.addEventListener('scroll',this.windowscroll)
                                                this.load && this.load.close();


                                            
                                        })


                                   // })
                                                    
                                //}






            document.body.scrollTop = document.documentElement.scrollTop = 0;


            


            


            function navactive(Index) {
                for (let i = 0; i < navitem.length; i++) {
                    navitem[i].classList.remove('navactive')
                }
                navitem[Index].classList.add('navactive')
                navxian.style.left = `calc(0.22rem * ${Index * 2 + 1} + 1.04rem * ${Index} + 0.52rem - 0.23rem)`
            }




            //获取元素距离顶部高度
            function getElementTop(el) {
                var actualTop = el.offsetTop
                var current = el.offsetParent
                while (current !== null) {
                    actualTop += current.offsetTop
                    current = current.offsetParent
                }
                return actualTop
            }




    },
    beforeDestroy (){
        window.removeEventListener('scroll',this.windowscroll)
    },
    filters:{
        costFilter:function(val){
            if (!val) return '*'
            var newval = String(val)
            if(newval.length <= 4 && val != 0){
                return newval + "万"
            }else{
                return newval/10000 + "亿"
            }
        },
        dataFilter:function(val){
            if (!val) return ''
            return val.substring(0,10)
        }
    }
}
</script>


<style lang="scss" scoped>



.topBox{
    width: 100%;
    padding: 0.38rem 0.22rem 0.22rem;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    background: #AE1F20;
    .companyNameBox{
        display: flex;
        margin-bottom: .3rem;
        img{
            width: 1.4rem;
            height: 1.4rem;
            display: block;
            object-fit: cover;
            margin-right: .3rem;
        }
        p{
            font-size: .4rem;
            font-weight: 400;
            color: #FFFFFF;
        }
    }
    .companyInfoBox{
        background: #852E2F;
        padding: 0.12rem 0.24rem;
        box-sizing: border-box;
        overflow: hidden;
        position: relative;
        .companyInfo1{
            font-size: .24rem;
            font-weight: 400;
            color: #FFFFFF;
            margin-bottom: .24rem;
        }
        .companyInfoLine{
            width: 100%;
            height: .02rem;
            margin-bottom: .16rem;
            background-color: #8F5151;
        }
        .companyInfo2Box{
            display: flex;
            justify-content: space-between;
            .companyInfo2Item{
                width: 32%;
                font-size: .24rem;
                font-weight: 400;
                color: #FFFFFF;
                text-align: center;
                margin-bottom: .08rem;
            }
            .companyInfo2Line{
                width: .02rem;
                height: .72rem;
                background-color: #8F5151;
            }
        }
    }
}

.companyProfileBox{
    padding: .24rem .28rem;
    box-sizing: border-box;
    background-color: #FFFFFF;
    position: relative;
    overflow: hidden;
    .companyProfileTitle{
        font-size: .34rem;
        font-weight: bold;
        color: #343434;
        margin-bottom: .26rem;
    }
    .companyProfile{
        font-size: .26rem;
        font-weight: 400;
        color: #A1A1A1;
        line-height: .36rem;
        text-align: justify;
    }
    .companyProfileS{
        display: -webkit-box;    
        -webkit-box-orient: vertical;    
        -webkit-line-clamp: 3;
        overflow: hidden;
    }
    .companyProfileJT{
        width: .2rem;
        height: .06rem;
        display: block;
        transform: rotateZ(0deg);
        transition: .5s;
        margin: .24rem auto 0;
    }
    .companyProfileJTHide{
        transform: rotateZ(-180deg);
    }
}






</style>